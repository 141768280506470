import React, { useState } from 'react';
import { Page, Toast, Frame } from '@shopify/polaris';
import { withFirebase } from '../../../providers/firebase'
import { navigate } from "gatsby"
import equal from "deep-equal"
import { doc } from 'firebase/firestore';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import getSymbolFromCurrency from 'currency-symbol-map';
import HideDeliveryMethodsForm from './hideDeliveryMethodsForm';
import DocStatus from '../../../components/docStatus';
import { createDeliveryCustomization, updateDeliveryCustomization, deleteDeliveryCustomization} from '../../../helpers/deliveryCustomization';
function CreateHideDeliveryMethods(props) {
    const { token, shop, location, firebase, host } = props
    const existingCustomization = (location && location.state && location.state.customization) || null
    console.log("existingCustomization", existingCustomization)
    const isEditing = !!existingCustomization
    const isCreating = !isEditing
  
    const initialEditingState = {
      id: existingCustomization && existingCustomization.id || null,
      deliveryMethodName: existingCustomization && existingCustomization.deliveryMethodName || '',
      deliveryMethodNameOption: existingCustomization && existingCustomization.deliveryMethodNameOption || 'nameContains',
      enabled: existingCustomization && existingCustomization.enabled || false,
      triggers: existingCustomization && existingCustomization.triggers || {
        conditions:{
            all:[
                {
                    any: [
                        {fact:'cartTotal',operator: 'greaterThanString',value: '0.00'}
                    ]
                }
                ]
        },
        event: { 
            type: 'foundout',
            params: {
              message: 'rule has found out!'
            }
        }
      },
      type: "custom field",
    }
    const [state, setState] = useState(initialEditingState)
    const [productPickerShow, setProductPickerShow] = useState('product')
    const [toastActive, setToastActive] = useState(null)
    const toggleToastActive = () => setToastActive(null)
    const shopDoc = doc(firebase.firestore, 'shops', shop);
    const [shopData, shopDataLoading, shopDataError] = useDocumentData(
      shopDoc
      );
      const [isSubmitting, setIsSubmitting] = useState(false)
      const currency = (shopData && shopData.shopData && shopData.shopData.currency) ? shopData.shopData.currency : 'USD';
      const locale = shopData && shopData.shopData && shopData.shopData.primary_locale || "en-US";
      const domain = shopData && shopData.shopData && shopData.shopData.domain
      const currencySymbol = getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : '$';
      let currentState = Object.assign({},state);
      let initialStateForCheck = Object.assign({},initialEditingState);
      delete currentState.enabled;
      delete initialStateForCheck.enabled;
      delete currentState.updatedAt;
      delete initialStateForCheck.updatedAt;
      const hasUnsavedChanges = isEditing && !equal(currentState, initialStateForCheck)
      const handleFormSubmit = async (type) => {
        console.log("type", type)
        if (isSubmitting) {
          return
        }
        setIsSubmitting(true)
        if (isEditing) {
          await updateDeliveryCustomization(state, token, shop, host)
          navigate(
            `/app/hidedeliverymethods/createHideDeliveryMethods`,
            {
              state: {customization: {...state, updatedAt: new Date().toISOString()}},
              replace: true,
            }
          )
        }else{
          console.log("saving")
          const result = await createDeliveryCustomization(state, token, shop, host)
          if(result?.data?.customizationId){
            navigate(
              `/app/hidedeliverymethods/hideDeliveryMethodsItem`,
              {
                state: {customization: {...state, id: result.data.customizationId.replace("gid://shopify/DeliveryCustomization/", ""), updatedAt: new Date().toISOString()}},
                replace: true,
              }
            )
          }else{
            if(result?.data?.error){
              setToastActive(result.data.error)
            }
          }
        }
        setIsSubmitting(false)
      }
      const handleCustomContentDelete = async () => {
        const result = await deleteDeliveryCustomization(state.id, shop, token);
        console.log("result", result)
        navigate('/app/customizations', {
          state: location.state,
          replace: true,
        })
      }
      
      console.log("isEditing", isEditing)
      const toastMarkup =
       toastActive ? (
      <Toast
      content={<div>
        <p style={{margin:'20px 0', textAlign:'left'}}>{toastActive}</p>
        <p style={{textAlign:'left'}}>
          <a href="javascript:void(0)" style={{color:'white'}} onClick={ () => window.open(
        `https://${domain}/admin/settings/shipping#local-pickup`,
        "_blank"
      )}>Click here to see and manage all your customizations</a></p></div>}
        onDismiss={toggleToastActive}
        duration={20000}
        error
      />
    ) : null
    return (
        <Page>
          <DocStatus isEditing={isEditing} location={location} name={initialEditingState.name} updatedAt={existingCustomization?.updatedAt} handleFormSubmit={handleFormSubmit} isSubmitting={isSubmitting} hasUnsavedChanges={hasUnsavedChanges} token={token} shop={shop} firebase={firebase} state={state} setState={setState} domain={domain} fullWidth title="Hide delivery method"/>
          <HideDeliveryMethodsForm token={token} shop={shop} location={location} firebase={firebase} host={host} state={state} setState={setState} productPickerShow={productPickerShow} setProductPickerShow={setProductPickerShow} currencySymbol={currencySymbol} isCreating={isCreating} isEditing={isEditing} isSubmitting={isSubmitting} handleFormSubmit={handleFormSubmit} hasUnsavedChanges={hasUnsavedChanges} handleCustomContentDelete={handleCustomContentDelete}/>
          <Frame>{toastMarkup}</Frame>
        </Page>
    );
}

export default withFirebase(CreateHideDeliveryMethods);
