import { axiosInterceptor } from "./axiosInterceptor"

const getDeliveryCustomization = (payload, token, shop, host) => {
  const axiosWithToken = axiosInterceptor(shop, host)
  return axiosWithToken.request({
    url: `/getDeliveryCustomization`,
    method: "post",
    data: payload,
    headers: {
      "X-Shopify-Access-Token": token,
      "X-Shopify-Shop-Domain": shop,
    },
  })
}

const createDeliveryCustomization = (payload, token, shop, host) => {
  const axiosWithToken = axiosInterceptor(shop, host)
  return axiosWithToken.request({
    url: `/createDeliveryCustomization`,
    method: "post",
    data: payload,
    headers: {
      "X-Shopify-Access-Token": token,
      "X-Shopify-Shop-Domain": shop,
    },
  })
}

const updateDeliveryCustomization = (payload, token, shop, host) => {
  const axiosWithToken = axiosInterceptor(shop, host)
  return axiosWithToken.request({
    url: `/updateDeliveryCustomization`,
    method: "post",
    data: payload,
    headers: {
      "X-Shopify-Access-Token": token,
      "X-Shopify-Shop-Domain": shop,
    },
  })
}

const deleteDeliveryCustomization = async (id, shop, token) => {
  const query = {
    query: `mutation deliveryCustomizationDelete($id: ID!) {
            deliveryCustomizationDelete(id: $id) {
        deletedId
        userErrors {
          field
          message
        }
      }
    }`,
    variables: {
      id: `gid://shopify/DeliveryCustomization/${id}`,
    },
  }
  const response = await fetch("/api/graphql", {
    method: "POST",
    body: JSON.stringify(query),
    headers: {
      "Content-Type": "application/json",
      "x-shopify-shop-domain": shop,
      "x-shopify-access-token": token,
    },
  })
}

export {
  getDeliveryCustomization,
  createDeliveryCustomization,
  updateDeliveryCustomization,
  deleteDeliveryCustomization,
}
